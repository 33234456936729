<script setup>
    import { onMounted } from 'vue';
    import zhCN from 'ant-design-vue/es/locale/zh_CN';
    import { checkLogIsOpen } from './utils/utils.js';
    import dayjs from 'dayjs';
    import 'dayjs/locale/zh-cn';
    dayjs.locale('zh-cn');
    onMounted(() => {
        // checkLogIsOpen();
    });
</script>

<template>
    <a-config-provider
        :locale="zhCN"
        :theme="{
            token: {
                colorPrimary: '#036b64',
            },
        }"
    >
        <router-view></router-view>
    </a-config-provider>
</template>

<style scoped>
    .logo {
        height: 6em;
        padding: 1.5em;
        will-change: filter;
        transition: filter 300ms;
    }

    .logo:hover {
        filter: drop-shadow(0 0 2em #646cffaa);
    }

    .logo.vue:hover {
        filter: drop-shadow(0 0 2em #42b883aa);
    }
</style>
