import {
    createApp
} from 'vue'
import App from './App.vue'
import pinia from './pinia'

import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/reset.css';
import '@/assets/css/rest.css';
import '@/assets/css/global.scss';
import 'swiper/css';


import router from '@/router/router.js';


const app = createApp(App);
// 防抖自定义指令
app.directive('debounce', {
    beforeMount(el, binding) {
        let timer = null;
        el.addEventListener(binding.arg, (e) => {
            console.log(e);
            timer && clearInterval(timer) && (timer = null)
            timer = setTimeout(binding.value, 500, e)
        })
    }
})
app.use(Antd).use(pinia).use(router).mount('#app');