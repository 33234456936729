import { createRouter, createWebHistory } from 'vue-router';
import { routes } from '@/router/config';

const routers = createRouter({
    // history: createWebHistory('/whln-admin/'),
    history: createWebHistory('/'),
    routes: routes,
    // base: '/whln-admin'
});

export default routers;
